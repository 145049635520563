<template>
  <div id="TravelPackages">
    Travel Packages
  </div>
</template>

<script>
export default {
  name: "TravelPackages",
  mounted() {
    window.scrollTo(0,0)
  }
}
</script>

<style lang="scss" scoped>

</style>